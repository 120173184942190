import {
  Container,
  OuterContainer,
  Logo,
  Links,
  Button,
  Hamburger,
  Menu,
  MenuContainer,
  MenuButton,
} from "./style";
import { ReactComponent as LogoSvg } from "../../assets/logo-black.svg";
import { ReactComponent as LogoTextSvg } from "../../assets/logo-text-black.svg";
import hamburger from "../../assets/icons/menu.png";
import hamburgerClose from "../../assets/icons/menu-close.png";
import { useState } from "react";
import { toTop } from "../../utils/functions";

function Navbar() {
  const [menuVisible, setMenuVisible] = useState(false);

  const linkClick = () => {
    setMenuVisible(false);
    toTop();
  };

  return (
    <OuterContainer>
      <Container>
        <Logo onClick={toTop} to="/">
          <Logo.Svg>
            <LogoSvg />
          </Logo.Svg>
          <Logo.TextSvg>
            <LogoTextSvg />
          </Logo.TextSvg>
        </Logo>
        <Links>
          <Links.Link onClick={toTop} to="/home">
            Главная
          </Links.Link>
          <Links.Link onClick={toTop} to="/about">
            О компании
          </Links.Link>
          <Links.Link onClick={toTop} to="/catalog">
            Каталог
          </Links.Link>
          <Links.Link onClick={toTop} to="/contact">
            контакты
          </Links.Link>
        </Links>
        <Button to="/order">Заказать</Button>
        <Hamburger
          onClick={() => setMenuVisible(!menuVisible)}
          visible={`${menuVisible}`}
        >
          <img className="open" src={hamburger} alt="Open menu" />
          <img className="close" src={hamburgerClose} alt="Open menu" />
        </Hamburger>
        <Menu visible={`${menuVisible}`} onClick={() => setMenuVisible(false)}>
          <MenuContainer onClick={(e) => e.stopPropagation()}>
            <Links.Link onClick={linkClick} to="/home">
              Главная
            </Links.Link>
            <Links.Link onClick={linkClick} to="/about">
              О компании
            </Links.Link>
            <Links.Link onClick={linkClick} to="/catalog">
              Каталог
            </Links.Link>
            <Links.Link onClick={linkClick} to="/contact">
              контакты
            </Links.Link>
            <MenuButton onClick={linkClick} to="/order">
              Заказать
            </MenuButton>
          </MenuContainer>
        </Menu>
      </Container>
    </OuterContainer>
  );
}

export default Navbar;
