import styled from "styled-components";
import { globalLittleContainer, greenButton } from "../../root/global";
import { Link, NavLink } from "react-router-dom";

export const OuterContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 20;
  height: 100%;
  background-color: #fafafa;
`;

export const Container = styled.div`
  height: 100%;
  ${globalLittleContainer}
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;

export const Logo = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
`;

Logo.Svg = styled.div`
  width: 55px;

  @media screen and (max-width: 850px) {
    width: 35px;
  }
  @media screen and (max-width: 650px) {
    svg {
      height: 55px;
    }
  }

  svg {
    width: 100%;
  }
`;

Logo.TextSvg = styled.div`
  width: 80px;

  @media screen and (max-width: 850px) {
    width: 60px;
  }
  @media screen and (max-width: 650px) {
    svg {
      height: 55px;
    }
  }

  svg {
    width: 100%;
  }
`;

export const Links = styled.div`
  display: flex;
  height: 80px;

  @media screen and (max-width: 850px) {
    height: 68px;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

Links.Link = styled(NavLink)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 20px;
  height: 100%;
  background-color: #fafafa;
  color: #333;
  font-family: Oswald;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 43.2px */
  text-transform: uppercase;
  transition: 0.2s linear;

  &:hover {
    background-color: #ededed;
  }

  @media screen and (max-width: 850px) {
    padding-inline: 10px;
    font-size: 15px;
  }
  @media screen and (max-width: 700px) {
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
`;

export const Button = styled(Link)`
  text-decoration: none;
  ${greenButton};

  @media screen and (max-width: 850px) {
    padding: 8px 18px;
    border-radius: 5px;
    font-size: 15px;
  }
  @media screen and (max-width: 400px) {
    display: none;
  }
`;

export const Hamburger = styled.button`
  border: none;
  background: transparent;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    transition: 0.15s linear;
  }

  .open {
    width: 100%;
    height: 100%;
    visibility: ${({ visible }) => (visible === "true" ? "hidden" : "visible")};
    opacity: ${({ visible }) => (visible === "true" ? 0 : 1)};
  }
  .close {
    width: 60%;
    height: 60%;
    right: 15%;
    visibility: ${({ visible }) => (visible === "true" ? "visible" : "hidden")};
    opacity: ${({ visible }) => (visible === "true" ? 1 : 0)};
  }

  @media screen and (min-width: 701px) {
    display: none;
  }
`;

export const Menu = styled.div`
  position: absolute;
  z-index: 8;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  padding-block: 20px;
  visibility: ${({ visible }) => (visible === "true" ? "visible" : "hidden")};
  opacity: ${({ visible }) => (visible === "true" ? 1 : 0)};
  transition: 0.2s linear;
`;

export const MenuContainer = styled.div`
  ${globalLittleContainer};
  margin-inline: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const MenuButton = styled(Link)`
  margin-top: 10px;
  text-decoration: none;
  ${greenButton};
  padding: 8px 18px;
  border-radius: 5px;
  font-size: 14px;

  @media screen and (min-width: 401px) {
    display: none;
  }
`;
