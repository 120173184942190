import styled from "styled-components";
import { globalLittleContainer, greenColor } from "./../../root/global";
import { Link } from "react-router-dom";

export const OuterContainer = styled.div`
  padding-block: 23px;
  background-color: ${greenColor};
`;

export const Container = styled.div`
  ${globalLittleContainer};
  margin-inline: auto;
  display: flex;
  align-items: center;
  gap: 80px;

  @media screen and (max-width: 700px) {
    gap: 40px;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const Logo = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
`;

Logo.Svg = styled.div`
  width: 55px;

  @media screen and (max-width: 850px) {
    width: 35px;
  }
  @media screen and (max-width: 650px) {
    svg {
      height: 55px;
    }
  }

  svg {
    width: 100%;
  }
`;

Logo.TextSvg = styled.div`
  width: 80px;

  @media screen and (max-width: 850px) {
    width: 60px;
  }
  @media screen and (max-width: 650px) {
    svg {
      height: 55px;
    }
  }

  svg {
    width: 100%;
  }
`;

export const RightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 700px) {
    gap: 15px;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const OneLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */

  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
`;

export const Line = styled.div`
  height: 1px;
  opacity: 0.5;
  background: #fff;
  border-radius: 1px;
`;

export const Bottom = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 700px) {
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 10px;

    div {
      text-align: start !important;
    }
  }
`;
