import { css, createGlobalStyle } from "styled-components";

export const greenColor = "#2BBB24";

export const userSelectText = css`
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`;

export const globalBigContainer = css`
  @media screen and (max-width: 1400px) {
    width: 94%;
  }

  @media screen and (min-width: 1400.01px) {
    width: 1230px;
  }
`;

export const globalLittleContainer = css`
  @media screen and (max-width: 1100px) {
    width: 94%;
  }

  @media screen and (min-width: 1100.01px) {
    width: 1000px;
  }
`;

export const greenButton = css`
  border: none;
  border-radius: 10px;
  background: ${greenColor};
  padding: 12px 25px;
  color: #fafafa;
  font-family: Oswald;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 43.2px */
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  background-image: linear-gradient(
    to right,
    ${greenColor} 0%,
    #2fff24 51%,
    ${greenColor} 100%
  );
  transition: 0.5s;
  background-size: 200% auto;

  &:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  /* Style Body */
  body {
      font-family: 'Montserrat', sans-serif;
      /* font-family: 'Oswald', sans-serif; */
      background-color: #fff;
      position: relative;
      z-index: 0;
  }
  /* Style Scrollbar and Selection */
  ::-webkit-scrollbar {
      width: 6px;
      height: 5px;
      background-color: #e0e3e3;
  }
  ::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
      /* background-color: ${greenColor}; */
      background: linear-gradient(0deg, rgba(43, 187, 36, 0.3) 0%, ${greenColor} 100%);
      border-radius: 6px;
  }
  *::selection {
      background-color: ${greenColor};
      color: #fff;
  }
`;
