import {
  Bottom,
  Container,
  Line,
  Links,
  Logo,
  OneLink,
  OuterContainer,
  RightSide,
} from "./style";
import { ReactComponent as LogoSvg } from "../../assets/logo-white.svg";
import { ReactComponent as LogoTextSvg } from "../../assets/logo-text-white.svg";
import { toTop } from "./../../utils/functions";

function Footer() {
  return (
    <OuterContainer>
      <Container>
        <Logo onClick={toTop} to="/">
          <Logo.Svg>
            <LogoSvg />
          </Logo.Svg>
          <Logo.TextSvg>
            <LogoTextSvg />
          </Logo.TextSvg>
        </Logo>
        <RightSide>
          <Links>
            <OneLink to="/">Главная</OneLink>
            <OneLink onClick={toTop} to="/about">
              О компании
            </OneLink>
            <OneLink onClick={toTop} to="/catalog">
              Каталог
            </OneLink>
            <OneLink onClick={toTop} to="/contact">
              Контакты
            </OneLink>
          </Links>
          <Line />
          <Bottom>
            <div>© ООО «SHAMS TECHNOLOGICAL SOLUTIONS»,  2023—2027</div>
            <div style={{ textAlign: "right" }}>
              © Оформление сайта. «Джедай Медиа», 2023
            </div>
          </Bottom>
        </RightSide>
      </Container>
    </OuterContainer>
  );
}

export default Footer;
