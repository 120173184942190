import { GlobalStyle } from "./global";
import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Loader from "../components/with-spinner/loader.jsx";
import ErrorBoundary from "../components/error-boundary/error-boundary.component";
import ToTopButton from "../components/to-top-button";
import HelmetComponent from "./helmet";

const HomePage = lazy(() => import("../pages/home-page"));
const AboutPage = lazy(() => import("../pages/about-page"));
const CatalogPage = lazy(() => import("../pages/catalog-page"));
const ProductPage = lazy(() => import("../pages/product-page"));
const ContactPage = lazy(() => import("../pages/contact-page"));
const OrderPage = lazy(() => import("../pages/order-page"));
const NoMatch404 = lazy(() => import("../pages/404"));

function App() {
  return (
    <>
      <GlobalStyle />
      <HelmetComponent />
      <Navbar />
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/catalog" element={<CatalogPage />} />
            <Route path="/products/:id" element={<ProductPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/order" element={<OrderPage />} />
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/*" element={<NoMatch404 />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
      <Footer />
      <ToTopButton />
    </>
  );
}

export default App;
