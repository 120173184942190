import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HelmetTitleProvider from "../helmet-title-provider";
import { NoMatch404Container, Title } from "./style";
import { toTop } from "./../../utils/functions";

function NoMatch404() {
  useEffect(() => {
    toTop();
  }, []);

  const navigate = useNavigate();

  const returnToHome = () => {
    navigate("/");
    toTop();
  };

  return (
    <NoMatch404Container>
      <HelmetTitleProvider title="Страница не найдена" />
      <Title>Страница не найдена</Title>
      <button onClick={returnToHome}>Главная страница</button>
    </NoMatch404Container>
  );
}

export default NoMatch404;
