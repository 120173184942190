import styled from "styled-components";
import { greenButton } from "../../root/global";

export const NoMatch404Container = styled.div`
  min-height: calc(100vh - 100px);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;

  button {
    ${greenButton}
    margin: 0 auto 25px;

    @media screen and (max-width: 850px) {
      padding: 8px 18px;
      border-radius: 5px;
      font-size: 15px;
    }
  }
`;

export const Title = styled.p`
  color: #333;
  /* h-header */
  font-family: Oswald;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 86.4px */
  text-transform: uppercase;
`;
